<script lang="ts">
const keepAliveComponents = 'AenderungOverviewPage,ApothekeOverviewPage,NutzerOverviewPage,PlanOverviewPage,NNFOverviewPage,RstOverviewPage'
</script>
<script setup lang="ts">
const commonStore = useCommonStore()

</script>

<template>
  <TheNavigation />

  <div v-if="!!commonStore.loading || !!commonStore.refreshing" class="backdrop z-69" />
  <div v-if="!!commonStore.loading" class="float absolute left-a z-20 h-full w-full flex items-center justify-center text-center">
    <div class="circle-loader z-70 h-30 w-30" />
  </div>
  <div v-if="!!commonStore.refreshing" class="float absolute left-a z-20 h-full w-full flex items-center justify-center text-center">
    <div class="circle-loader z-70 h-30 w-30" />
  </div>

  <div class="h-full flex overflow-hidden bg-gray-50 pt-15 dark:bg-gray-900">
    <TheSidebar />

    <div id="main-content" class="relative h-full w-full overflow-y-auto overflow-y-scroll bg-white lg:ml-64 dark:bg-gray-800">
      <main class="h-full px-4 py-4">
        <RouterView v-slot="{ Component }">
          <keep-alive :include="keepAliveComponents">
            <component :is="Component" />
          </keep-alive>
        </RouterView>
      </main>
      <!-- TheFooter / -->
    </div>
  </div>
</template>
