<!-- eslint-disable unocss/order -->
<!-- eslint-disable unocss/order -->
<script setup lang="ts">
import TheNutzerModal from '~/components/TheNutzerModal.vue'
import LogoLight from '~/assets/apo-logo.png'

const userStore = useUserStore()
const breakpoints = useBreakpoints({
  'xs': '320px',
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  '2xl': '1536px',
  'xxl': '1536px',
})

const frontendVersion = import.meta.env.PACKAGE_VERSION
const backendVersion = userStore.backendVersion

const nutzerModal = ref<InstanceType<typeof TheNutzerModal> | null>(null)

function showNutzerModal() {
  nutzerModal.value?.show()
}

function abmelden() {
  userStore.setUser(undefined)
  userStore.setToken(undefined)
  localStorage.removeItem('token')
}

onMounted(() => {
  const sidebarRef = document.getElementById('default-sidebar')

  if (sidebarRef) {
    const toggleSidebarMobile = (sidebarRef: any, sidebarBackdrop: any, toggleSidebarMobileHamburger: any, toggleSidebarMobileClose: any) => {
      sidebarRef.classList.toggle('hidden')
      sidebarBackdrop.classList.toggle('hidden')
      toggleSidebarMobileHamburger.classList.toggle('hidden')
      toggleSidebarMobileClose.classList.toggle('hidden')
    }

    const toggleSidebarMobileEl = document.getElementById('toggleSidebarMobile')
    const sidebarBackdrop = document.getElementById('sidebarBackdrop')
    const toggleSidebarMobileHamburger = document.getElementById('toggleSidebarMobileHamburger')
    const toggleSidebarMobileClose = document.getElementById('toggleSidebarMobileClose')
    const toggleSidebarMobileSearch = document.getElementById('toggleSidebarMobileSearch')

    toggleSidebarMobileSearch?.addEventListener('click', () => {
      toggleSidebarMobile(sidebarRef, sidebarBackdrop, toggleSidebarMobileHamburger, toggleSidebarMobileClose)
    })
    toggleSidebarMobileEl?.addEventListener('click', () => {
      toggleSidebarMobile(sidebarRef, sidebarBackdrop, toggleSidebarMobileHamburger, toggleSidebarMobileClose)
    })
    sidebarBackdrop?.addEventListener('click', () => {
      toggleSidebarMobile(sidebarRef, sidebarBackdrop, toggleSidebarMobileHamburger, toggleSidebarMobileClose)
    })

    // mobile - X < LG
    // desktop - X >= LG
    if (breakpoints.greaterOrEqual('lg').value)
      sidebarRef.classList.remove('hidden')
  }

  setTimeout(() => initFlowbite(), 500)
})
</script>

<template>
  <nav class="fixed z-60 w-full border-b border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
    <div class="px-3 py-3.5 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <!-- open/close mobile menu -->
          <button id="toggleSidebarMobile" aria-controls="default-sidebar" type="button" class="cursor-pointer rounded p-2 text-gray-600 lg:hidden focus:bg-gray-100 hover:bg-gray-100 dark:text-gray-400 hover:text-gray-900 focus:ring-2 focus:ring-gray-100 dark:focus:bg-gray-700 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">
            <span class="sr-only">Sidebar umschalten</span>
            <div id="toggleSidebarMobileHamburger" class="h-6 w-6" i-mdi-menu />
            <div id="toggleSidebarMobileClose" class="hidden h-6 w-6" i-mdi-close />
          </button>

          <!-- logo + title -->
          <a href="/" class="ml-2 flex md:mr-24">
            <img :src="LogoLight" class="mr-3 h-8 w-8" alt="sberg Notdienstportal Logo">
            <span class="self-center whitespace-nowrap text-xl text-black font-semibold sm:text-2xl dark:text-white">Notdienstportal</span>
          </a>
        </div>
        <div class="flex items-center">
          <!-- Version Frontend/Backend -->
          <button data-tooltip-target="tooltip-version" type="button" class="relative rounded-lg text-sm text-gray-500 hover:cursor-help dark:text-gray-400 p-1">
            <div i="mdi-information-variant" class="h-6 w-6 rounded-full text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
          </button>
          <div id="tooltip-version" role="tooltip" class="tooltip invisible absolute z-100 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm text-white font-medium opacity-0 shadow-sm transition-opacity duration-300">
            Version Frontend: {{ frontendVersion }}<br>
            Version Backend: {{ backendVersion }}
            <div class="tooltip-arrow" data-popper-arrow />
          </div>

          <!-- Hell/Dunkel Switch -->
          <button data-tooltip-target="tooltip-toggle" type="button" class="relative rounded-lg text-sm text-gray-500 hover:bg-gray-100 dark:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-700 dark:focus:ring-gray-700 p-1" @click="toggleDark()">
            <Transition name="fade">
              <div v-if="isDark" i="mdi-weather-sunny" class="h-6 w-6 rounded-full text-amber transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" />
              <div v-else i="mdi-weather-night" class="h-6 w-6 rounded-full text-indigo transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" />
            </Transition>
          </button>
          <div id="tooltip-toggle" role="tooltip" class="tooltip invisible absolute z-100 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm text-white font-medium opacity-0 shadow-sm transition-opacity duration-300">
            Dunkelmodus umschalten
            <div class="tooltip-arrow" data-popper-arrow />
          </div>

          <!-- Profile -->
          <div v-if="userStore.savedUser" class="flex items-center">
            <div>
              <button id="dropdownInformationButton" data-tooltip-target="tooltip-menu" type="button" class="flex items-center rounded-lg text-sm text-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 p-1" aria-expanded="false" data-dropdown-toggle="dropdownInformation" data-dropdown-placement="bottom">
                <span class="sr-only">Menü öffnen</span>
                <div i-mdi-account-circle class="h-6 w-6 rounded-full text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" />
                <span class="font-bold">{{ userStore.savedUser.nutzername }}</span>
              </button>
              <div id="tooltip-menu" role="tooltip" class="tooltip invisible absolute z-100 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm text-white font-medium opacity-0 shadow-sm transition-opacity duration-300">
                Menü öffnen
                <div class="tooltip-arrow" data-popper-arrow />
              </div>
            </div>
            <!-- Dropdown menu -->
            <div id="dropdownInformation" class="z-100 my-4 hidden list-none b-1 rounded bg-white text-base shadow divide-y divide-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:divide-gray-600">
              <div class="px-4 py-3" role="none">
                <p class="text-sm text-gray-900 dark:text-white" role="none">
                  Eingeloggt als
                </p>
                <span class="text-sm text-gray-900 font-bold dark:text-white" role="none">{{ userStore.savedUser?.nutzername }}</span>
              </div>
              <ul class="min-w-300px py-1" role="none">
                <li>
                  <a
                    href="#"
                    class="block flex items-center px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="showNutzerModal"
                  >
                    <div i-mdi-cog class="mr-2 h-5 w-5 rounded-full text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" />
                    Einstellungen
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="block flex items-center px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="abmelden"
                  >
                    <div i-mdi-exit-to-app class="mr-2 h-5 w-5 rounded-full text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" />
                    Abmelden
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <TheNutzerModal ref="nutzerModal" />
</template>
