import * as Sentry from '@sentry/vue'
import type { UserModule } from '~/types'

const frontendVersion = import.meta.env.PACKAGE_VERSION

export const install: UserModule = ({ app, router }) => {
  Sentry.init({
    app,
    dsn: 'https://80f99ff5003ab0a3c39d3cacdd68696c@o206628.ingest.us.sentry.io/4507708672573440',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      // Perfekt für Fehleranalyse aber kostet Performance
      // Sentry.replayIntegration(),
    ],

    release: frontendVersion,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/notdienst\.sberg\.net\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  })
}
